@media only screen and (max-width: 767.98px) {
  .card {
    padding: 3% !important;
  }

  .card-header {
    padding: 3% !important;

  }

  .card-body {
    padding: 3% !important;
  }
}

@media (max-width: 575.98px) {
  h1 {
    font-size: 2rem !important;
  }

  .text {
    font-size: 1.20rem !important;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  h1 {
    font-size: 2.5rem !important;
  }

  .text {
    font-size: 1.30rem !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  h1 {
    font-size: 3vw !important;
  }

  .text {
    font-size: 1.40rem !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  h1 {
    font-size: 3rem !important;
  }

  .text {
    font-size: 1.40rem !important;
  }
}

@media (min-width: 1200px) {
  h1 {
    font-size: 4rem !important;
  }

  .text {
    font-size: 1.5rem !important;
  }
}

.before {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.after {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}